.experience__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  place-content: center;
  font-size: 0.8rem;
  text-align: center;
}

.experience__container > div {
  background: var(--color-bg-variant);
  /* padding: 2.4rem 5rem; */
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.light .experience__container > div {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.experience__container > div:hover {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.light .experience__container > div:hover {
  border-color: transparent;
}

.experience__container > div h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: var(--color-primary);
}

.experience__content {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem;
}

.experience__details {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.experience__details-icon {
  margin-top: 6px;
  color: var(--color-primary);
}

.larger-icon.experience__details-icon {
  font-size: 1.5rem;
}

/* ============ Media Queries ============ */

@media screen and (max-width: 1024px) {
  .experience__container {
    grid-template-columns: 1fr;
  }
  .experience__container > div {
    width: 100%;
    padding: 0.5rem;
    margin: 0 auto;
  }

  /* .experience__content {
    padding: 1rem;
  } */
}

@media screen and (max-width: 600px) {
  .experience__container {
    width: 100%;
    gap: 1.2rem;

    padding: 2rem 1rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: 600px) {
  .experience__container > div {
    width: 100%;

    margin: 0 auto;
  }
}

@media screen and (min-width: 780px) {
  .experience__container {
    font-size: 1rem;
  }

  .experience__frontend,
  .experience__backend {
    padding: 1rem;
  }
}
