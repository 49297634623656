.portfolio__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
}

.portfolio__item {
  background: var(--color-bg-variant);
  padding: 2rem;
  border-radius: 2rem;
  border: 1px solid transparent;
  transition: var(--transition);
}

.portfolio__item:hover {
  border-color: var(--color-primary-variant);
  background: transparent;
  transform: translateY(-10px);
  transition: var(--transition);
}

.portfolio__item:hover .title-container__title {
  color: var(--color-primary);
}

.light .portfolio__item {
  background: transparent;
  border-color: var(--color-primary-variant);
}

.light .portfolio__item:hover {
  border-color: transparent;
}

.portfolio__item-image {
  border-radius: 1.5rem 1.5rem 0 0;
  overflow: hidden;
  height: 250px;
  object-fit: cover;
}

.portfolio__item-img {
  height: 250px;
}

.portfolio__item h3 {
  margin: 1.2rem 0 1.2rem;
}

.portfolio__item-cta {
  display: flex;
  /* flex-wrap: wrap; */
  gap: 1rem;
}

.title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.portfolio-btn:hover {
  color: var(--color-primary);
}

.portfolio-tools {
  font-family: "Courier New", Courier, monospace;
}

/* ============ Media Queries ============ */

@media screen and (max-width: 1024px) {
  .portfolio__container {
    grid-template-columns: 1fr 1fr;
    gap: 1.2rem;
  }
}

@media screen and (max-width: 600px) {
  .portfolio__container {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
}
