@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;1,300&display=swap");

:root {
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
  --transition: all 0.2s ease;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  --color-bg: #2c2c6c;
  /* --color-bg-variant: #2c2c6c; */
  --color-bg-variant: #121212;
  --color-primary: #bb86fc;
  /* --color-primary-variant: rgba(77, 181, 255, 0.4); */
  --color-primary-variant: #bb86fc;
  --color-primary-font: #fff;
  --color-light: rgba(255, 255, 255, 0.6);

  font-family: "Poppins", sans-serif;
  background: var(--color-bg-variant);
  color: var(--color-primary-font);
  line-height: 1.7;
  /* background-image: url("../src/assets/myself\ copy.jpeg"); */
}

.light {
  --color-bg: #ffffff;
  --color-primary: #bb86fc;
  /* --color-primary-variant: rgba(77, 181, 255, 0.4); */
  --color-primary-variant: #bb86fc;
  --color-primary-font: rgb(0, 0, 0);
  --color-light: rgba(0, 0, 0, 0.6);

  background: var(--color-bg);
}

html:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #bb86fc;
  height: 8px;
  opacity: 0;
  z-index: 10;
  animation: slide 0.7s ease-out forwards;
}

@keyframes slide {
  from {
    opacity: 0;
    width: 0;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

/*
  body: "primary-font",
  textColor: "#000",
  headingColor: "#d23669",
  background: "transparent",
*/
/* ========= light mode ========= */
.light-mode {
  background: #ffffff;
  color: black;
}

.light-hide {
  display: hide;
}

/*  ============= General Styles ============= */
.container {
  width: var(--container-width-lg);
  margin: 0 auto;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 500;
}

h1 {
  font-size: 2.5rem;
}

section {
  margin-top: 8rem;
}

section > h2,
section > h5 {
  text-align: center;
  color: var(--color-light);
}

section > h2 {
  color: var(--color-primary);
  margin-bottom: 3rem;
}

.text-light {
  color: var(--color-light);
}
a {
  /* color: var(--color-primary); */
  color: inherit;

  transition: var(--transition);
}

a:hover {
  color: var(--color-primary-font);
}

.btn {
  width: max-content;
  display: inline-block;
  color: var(--color-primary);
  padding: 0.75rem 1.2rem;
  border-radius: 0.4rem;
  cursor: pointer;
  border: 1px solid var(--color-primary);
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-primary-font);
  color: var(--color-bg);
  border-color: transparent;
  opacity: 0.7;
}

.btn-primary {
  background: var(--color-primary);
  color: var(--color-bg);
}

img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.swiper-button-prev,
.swiper-button-next {
  color: var(--color-primary);
  background-color: var(--color-bg-variant);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.light .swiper-button-prev,
.light .swiper-button-next {
  color: var(--color-primary);
  background-color: var(--color-light);
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 1.5rem;
}

.swiper-pagination-bullet-active {
  background-color: var(--color-primary);
}

/* ============ Media Queries ============ */

@media screen and (max-width: 600px) {
  .container {
    width: var(--container-width-sm);
  }
  section > h2 {
    margin-bottom: 6rem;
  }
}

@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }
  section {
    margin-top: 6rem;
  }
}
