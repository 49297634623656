.nav-container {
  background: rgba(0, 0, 0, 0.3);
  display: block;
  padding: 0.5rem 1.2rem;
  z-index: 10000;
  position: fixed;
  left: 49%;
  transform: translateX(-50%);
  display: flex;
  bottom: 2rem;
  gap: 0.8rem;
  border-radius: 3rem;
  backdrop-filter: blur(15px);
  display: flex;
}

.light nav {
  border: 1px solid;
  border-color: var(--color-primary-variant);
  background: #fff;
}
.nav-container a {
  background: transparent;
  padding: 0.5rem;
  border-radius: 50%;
  display: flex;
  color: var(--color-light);
  font-size: 0.8rem;
}

.nav-container a:hover {
  background: rgba(0, 0, 0, 0.3);
}

.nav-container a.active {
  color: var(--color-primary-font);
  box-shadow: 0px 1px 7px var(--color-primary);
}

/* ============ Media Queries ============ */

@media screen and (min-width: 780px) {
  .nav-container {
    padding: 0.7rem 1.7rem;
  }

  .nav-container a {
    padding: 0.9rem;
    font-size: 1.1rem;
  }
}
