.visible {
  background: transparent;
  cursor: pointer;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 999999;
  font-weight: 300;
  font-size: 0.6rem;
}

.scrollup {
  border-radius: 50%;
  box-shadow: 1px 0px 10px var(--color-primary);
  color: var(--color-primary-variant);
  width: 35px;
  height: 35px;
  font-size: 8rem;
}
.invisible {
  display: none;
}

/* ============ Media Queries ============ */

@media screen and (min-width: 780px) {
  .visible {
    font-size: 0.9rem;
    bottom: 3rem;
    right: 2rem;
  }
}
