.errFocus:focus {
  border: 3px solid grey;
  line-height: 1em;
}

.modal__container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.openModal {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 25%;
  bottom: 25%;
  right: 25%;
  left: 25%;
  z-index: 99;
  font-size: 1.5rem;
  animation: modal-bg-show 0.3s;
}

.modal__container button {
  padding: 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
}

@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
